<template>
  <div id="foot">
    <b-container fluid class="top text-center">
      <b-row>
        <b-col>
          <b-img :src="require('../assets/img/index/foot_icon1.png')"></b-img>
          <div class="des">365天客户服务支持</div>
        </b-col>
        <b-col>
          <b-img :src="require('../assets/img/index/foot_icon2.png')"></b-img>
          <div class="des">1v1专业技术顾问指导</div>
        </b-col>
        <b-col>
          <b-img :src="require('../assets/img/index/foot_icon3.png')"></b-img>
          <div class="des">简单易懂，傻瓜操作</div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bottom">
      <b-row>
        <b-col class="bottom1" sm="4" cols="12">
          <div class="title" :class="visible1 ? null : 'collapsed'" :aria-expanded="visible1 ? 'true' : 'false'"
            aria-controls="collapse-1" @click="isPhone?visible1 = !visible1:null">
            <div>联系我们</div>
            <b-icon-chevron-up></b-icon-chevron-up>
          </div>
          <b-collapse v-model="visible1" id="collapse-1">
            <div class="bottom1_content">
              <div>公司：无锡中巢建材科技有限公司</div>
              <div>地址：江苏省无锡市经开区太湖新城秀水坊2号楼</div>
              <div>电话：0510-85186908</div>
              <div>
                <div>销售热线：</div>
                <div>
                  <!-- <div>测量大师张张 15161532909</div>
                  <div>测量大师小陈 15050679256</div>
                  <div>测量大师可凡 15961733368</div> -->
                  <div>测量大师彭世灯：18583630588</div>
                  <div>测量大师清清：17315057717</div>
                </div>
              </div>
              <div>总部：江苏新格尔人居科技有限公司</div>
              <!-- <div>电话：0515-85186908</div> -->
            </div>
          </b-collapse>
        </b-col>
        <b-col class="bottom1" sm="2" cols="12">
          <div class="title" :class="visible2 ? null : 'collapsed'" :aria-expanded="visible2 ? 'true' : 'false'"
            aria-controls="collapse-2" @click="isPhone?visible2 = !visible2:null">
            <div>测量大师产品</div>
            <b-icon-chevron-up></b-icon-chevron-up>
          </div>
          <b-collapse v-model="visible2" id="collapse-2">
            <div class="bottom1_content">

              <div>
                <b-link target="_blank" href="#">测量大师APP</b-link>
              </div>
              <div>
                <b-link target="_blank" href="http://me-customer.zcjc.vip/#/login">经销商管理后台</b-link>
              </div>
              <div>
                <b-link target="_blank" href="http://factory.zcjc.vip/#/login">工厂智能决策平台</b-link>
              </div>
              <div>
                <b-link href="#" v-b-modal.modal-2>中巢装修(业主小程序)</b-link>
              </div>
              <div>
                <b-link href="#/">测量大师官网</b-link>
              </div>
            </div>
          </b-collapse>
        </b-col>
        <b-col class="bottom1" sm="3" cols="12">
          <div class="title" :class="visible3 ? null : 'collapsed'" :aria-expanded="visible3 ? 'true' : 'false'"
            aria-controls="collapse-3" @click="isPhone?visible3 = !visible3:null">
            <div>生产、仓库软件推荐</div>
            <b-icon-chevron-up></b-icon-chevron-up>
          </div>
          <b-collapse v-model="visible3" id="collapse-3">
            <div class="bottom1_content">
              <div>
                <b-link target="_blank" href="http://www.menccc.com/#">门窗CC</b-link>
              </div>
              <div>
                <b-link target="_blank" href="http://kevke.com/kshow/index.php?r=main">K秀导购</b-link>
              </div>
              <div>
                <b-link target="_blank" href="http://www.thinkerx.com/offical/men/index">铝门软件</b-link>
              </div>
              <div>
                <b-link target="_blank" href="http://www.thinkerx.com/offical/men/index">神雕软件</b-link>
              </div>
              <div>
                <b-link target="_blank" href="https://www.eggrj.com/">柜柜APP</b-link>
              </div>
              <div>
                <b-link target="_blank" href="https://windowcc.com/home">画门窗</b-link>
              </div>
              <!-- <div>
                <b-link target="_blank"
                        href="#">材神金服</b-link>
              </div> -->
            </div>
          </b-collapse>
        </b-col>
        <b-col class="bottom1" sm="3" cols="12">
          <b-row class="text-center ewm">
            <b-col cols="6">
              <b-img class="foot_img" :src="require('../assets/img/index/foot_img1.png')"></b-img>
              <p>测量大师官方抖音</p>
            </b-col>
            <b-col cols="6">
              <b-img class="foot_img" :src="require('../assets/img/index/foot_img2.png')"></b-img>
              <p>测量大师微信公众号</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="text-center bottom_text">
        <b-link to="/privacy">隐私协议</b-link> | <b-link to="/license">用户协议</b-link>
      </div>
      <div class="text-center bottom_text">
        <span>2010-2021 </span>
        <span>SiteStar,All Rights Reserved. </span>
        <span>无锡中巢建材科技有限公司 </span>
        <a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2020056865号</a>
      </div>
    </b-container>
    <b-modal id="modal-2" ok-only title="中巢装修(业主小程序)">
      <b-img class="zc" :src="require('../assets/img/zc.jpg')"></b-img>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'foot',
    data() {
      return {
        visible1: false,
        visible2: false,
        visible3: false,
        screenWidth: document.body.clientWidth, // 屏幕尺寸
      }
    },
    mounted() {
      this.onResize()
      window.addEventListener("resize", () => {
        this.screenWidth = document.body.clientWidth
        this.onResize()
      })
    },
    computed: {
      isPhone() {
        if (this.screenWidth < 576) {
          return true;
        } else {
          return false;
        }

      },

    },
    destroyed() {
      window.removeEventListener("resize", this.onResize)
    },
    methods: {
      onResize() {
        if (this.screenWidth < 576) {
          this.visible1 = false
          this.visible2 = false
          this.visible3 = false
        } else {
          this.visible1 = true
          this.visible2 = true
          this.visible3 = true
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  #foot {
    background: rgb(25, 36, 62);
    color: white;

    .top {
      border-bottom: 1px solid #999999;
      padding: 2.5rem 15px;

      .des {
        font-size: 0.8rem;
        margin-top: 1rem;
      }

      img {
        width: 3rem;
      }
    }

    .bottom {
      padding: 1rem;

      .bottom_text {
        font-size: 0.8rem;
        color: #848484;

        a {
          color: #848484;

          &:hover {
            color: #007bff;
          }
        }
      }

      .bottom1 {
        margin-bottom: 1rem;

        .title {
          font-size: 1rem;
          margin-bottom: 0.5rem;
          outline: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          &.collapsed /deep/ .b-icon {
            transform: rotate(180deg);
          }

          /deep/ .b-icon {
            display: block;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 10px;
            top: auto;
            transition: transform 0.2s ease-out;
          }
        }

        .bottom1_content {
          font-size: 0.8rem;
          color: #c4c4c4;

          &>div {
            margin-bottom: 0.5rem;
            display: flex;
          }

          a {
            color: #c4c4c4;

            &:hover {
              color: #007bff;
            }
          }
        }

        .ewm {
          color: #c4c4c4;
          font-size: 0.8rem;

          p {
            margin-top: 0.5rem;
          }
        }

        .foot_img {
          width: 7rem;
        }
      }
    }
  }

  .zc {
    display: block;
    margin: auto;
    width: 13rem;
  }

  @media screen and (min-width: 576px) {
    #foot {
      .top {
        border-bottom: 1px solid #999999;
        padding: 3rem 15px;

        .des {
          font-size: 0.9rem;
          margin-top: 0rem;
        }

        img {
          width: 5rem;
        }
      }

      .bottom {
        padding: 3rem 10rem 1rem;

        .bottom1 {
          .title {
            /deep/ .b-icon {
              display: none;
            }
          }
        }
      }
    }
  }
</style>