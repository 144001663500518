<template>
  <div>
    <b-navbar toggleable="lg" :type="type" :variant="variant" fixed="top" class="top_nav" ref="top_nav">
      <b-navbar-brand @click="toIndex">
        <b-img class="logo" :src="require('../assets/img/index/logo.png')"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle @click="aa" target="nav-collapse1"></b-navbar-toggle>

      <b-collapse id="nav-collapse1" ref="nav" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/solution" exact exact-active-class="active">解决方案</b-nav-item>
          <b-nav-item to="/production" exact exact-active-class="active">产品中心</b-nav-item>
          <!-- <b-nav-item to="/case"
                      exact
                      exact-active-class="active">客户案例</b-nav-item> -->
          <b-nav-item to="/news" exact exact-active-class="active">产品动态</b-nav-item>
          <b-nav-item to="/partner" exact exact-active-class="active">合伙人招募</b-nav-item>
          <b-nav-item to="/about" exact exact-active-class="active">关于我们</b-nav-item>
        </b-navbar-nav>
        <div class="ml-auto">
          <b-dropdown id="dropdown-1" variant="outline-light" text="登录" class="mr-4">
            <b-dropdown-item @click="toCustomer">用户管理后台登录</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="toFactory">工厂智能决策平台登录</b-dropdown-item>
          </b-dropdown>
          <b-button pill class="free" variant="primary" @click="toTry">免费试用</b-button>
        </div>

      </b-collapse>
    </b-navbar>
    <div class="dialog" v-show="isShow">
      <div class="content">
        <div class="title">温馨提示</div>
        <div class="des">手机暂不支持登录，请至电脑端登录</div>
        <div class="img">
          <b-img :src="require('../assets/img/index/phone_no.png')"></b-img>
        </div>
        <div class="toback" @click="close">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "head1",
    data() {
      return {
        variant: "transparent",
        type: "dark",
        screenWidth: document.body.clientWidth,
        isShow: false
      }
    },
    props: {
      background: {
        type: String,
        required: false
      },
      isIndex: {
        type: Boolean,
        required: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.btn_pos);
      if (this.background == "dark") {
        this.variant = "dark";
        this.type = "dark";
      }
      if (this.background == "transparent") {
        this.variant = "transparent";
        this.type = "dark";
      }
      if (this.isIndex && this.screenWidth > 576) {
        this.$refs.top_nav.$el.style.top = 50 + "px"
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.btn_pos);
    },
    beforeRouteLeave(to, from, next) {
      // ...
      window.removeEventListener('scroll', this.btn_pos);
      next();
    },
    methods: {
      toTry() {
        this.$router.push({
          name: "Try"
        })
      },
      toCustomer() {
        if (this.screenWidth <= 576) {
          this.isShow = true;
        } else {
          window.open("http://me-customer.zcjc.vip/")
        }

      },
      toFactory() {
        if (this.requiredscreenWidth <= 576) {
          this.isShow = true;
        } else {
          window.open("http://factory.zcjc.vip/")
        }

      },
      aa() {
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        console.log(this.$refs.nav.$el.className)
        if (this.background == "transparent") {
          if (scrollTop == 0) {
            if (this.$refs.nav.$el.className == "navbar-collapse collapse show") {

              this.variant = "transparent";
              this.type = "dark";
            } else {
              this.variant = "dark";
              this.type = "dark";
            }
          }
        }
      },
      btn_pos: function () {
        //滚动条的高度
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (this.background == "transparent") {
          if (this.isIndex && this.screenWidth > 576) {
            if (scrollTop < 50) {
              this.$refs.top_nav.$el.style.top = (50 - scrollTop) + "px"
              this.variant = "transparent";
              this.type = "dark";
            } else {
              this.$refs.top_nav.$el.style.top = 0 + "px";
              this.variant = "dark";
              this.type = "dark";
            }
          } else {
            if (scrollTop == 0) {
              this.$refs.top_nav.$el.style.top = 0 + "px"
              this.variant = "transparent";
              this.type = "dark";
            } else {
              this.$refs.top_nav.$el.style.top = 0 + "px";
              this.variant = "dark";
              this.type = "dark";
            }
          }
        } else {

          if (this.isIndex) {
            if (scrollTop < 50 && this.screenWidth > 576) {
              this.$refs.top_nav.$el.style.top = (50 - scrollTop) + "px"
            } else {
              this.$refs.top_nav.$el.style.top = 0 + "px";
            }
          } else {
            if (scrollTop == 0) {
              this.$refs.top_nav.$el.style.top = 0 + "px"
            } else {
              this.$refs.top_nav.$el.style.top = 0 + "px";
            }
          }

        }
        //滚动条的高度>可视区的高度
      },
      toIndex() {
        this.$router.push({
          name: "Index"
        })
      },
      showPhone() {
        this.isShow = true;
      },
      close() {
        this.isShow = false;
      }
    }
  }
</script>

<style lang="less" scoped>
  .top_nav {
    top: 0px;
    background: black;

    /deep/ .btn {
      font-size: 0.8rem;
    }
  }

  .logo {
    width: 120px;
    cursor: pointer;
  }

  .free {
    padding: 0.375rem 1.5rem;
  }

  .bg-transparent {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.3);

    .content {
      position: relative;
      width: 18rem;
      margin: 50vh auto 0;
      transform: translateY(-50%);
      background: white;
      padding: 1rem 1.5rem;
      border-radius: 6px;

      .title {
        text-align: center;
        font-size: 1.2rem;
        padding-bottom: 1.5rem;
      }

      .des {
        font-size: 0.9rem;
        text-align: center;
        padding-bottom: 0.5rem;
      }

      .img {
        text-align: center;
        padding-bottom: 1rem;

        img {
          width: 80%;
        }
      }

      .toback {
        width: 100%;
        padding: 0.8rem 0;
        text-align: center;
        background: #2b85e4;
        color: white;
        font-size: 0.8rem;
        border-radius: 6px;
      }
    }
  }

  .navbar-expand-sm .navbar-nav {
    padding-bottom: 0.5rem;

    .nav-link {
      font-size: 0.8rem;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
  }

  #nav-collapse1 {
    /deep/ .dropdown-menu {
      font-size: 0.8rem;
    }

    /deep/ #dropdown-1 {
      .btn {
        border-radius: 50rem;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .top_nav {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .navbar-nav {
      padding-bottom: 0;

      .nav-link {
        font-size: 0.8rem;
        padding: 0.5rem 1rem 0.5rem 1.5rem;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .top_nav {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .navbar-nav {
      padding-bottom: 0;

      .nav-link {
        font-size: 0.8rem;
        padding: 0.5rem 1rem 0.5rem 1.5rem;
      }
    }
  }
</style>