<template>
  <div>
    <div class="right"
         :class="[ !transition ? 'fixed-transition' : '']">
      <div class="top"
           @click="toTry"><span>免费</span><span>试用</span></div>
      <div class="bottom"
           @click="showPhone">
        联系我们<b-img :src="require('../assets/img/index/call1.png')"></b-img>
      </div>
    </div>
    <div class="up"
         v-show="gotop"
         @click="up">
      <b-icon-capslock></b-icon-capslock>
    </div>
    <div class="dialog"
         v-show="isShow">
      <div class="content">
        <b-img class="img_phone"
               :src="require('../assets/img/index/call.png')"></b-img>
        <b-img class="close1"
               @click="close"
               :src="require('../assets/img/index/close.png')"></b-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "right",
  data () {
    return {
      transition: true,
      gotop: false,
      scrollTop: 0,
      isShow: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll); // 监听页面滑动
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toTry () {
      this.$router.push({ name: "Try" })
    },
    handleScroll () { // 每次滑动都会执行函数
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      this.transition = false;
      if (scrolltop > this.scrollTop) {
        this.transition = false;
      } else {
        this.transition = true;
      }
      this.scrollTop = scrolltop


      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    up () {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    showPhone () {
      this.isShow = true;
    },
    close () {
      this.isShow = false;
    }
  }
}
</script>

<style lang="less" scoped>
.right {
  position: fixed;
  right: 10px;
  top: 40vh;
  width: 2.8rem;
  z-index: 99;
  box-shadow: 0px 0px 2px #b4b4b4;
  transition: 0.5s ease-in-out;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  .top {
    background: linear-gradient(to bottom left, #56e3ff, #33a7fe);
    color: white;
    font-size: 0.9rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0;
    cursor: pointer;
  }
  .bottom {
    background: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0.7rem 0.5rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    line-height: 1.3rem;
    img {
      width: 1.6rem;
      margin-top: 0.4rem;
    }
  }
}
.up {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  color: #007bff;
  background: #e0eefd;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 10px;
  top: 70vh;
  z-index: 99;
  cursor: pointer;
}
.fixed-transition {
  right: -60px;
  opacity: 0.4;
  transition: 0.5s ease-in-out;
}
#modal-1 {
  p {
    font-size: 1.5rem;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.3);
  .content {
    position: relative;
    width: 16rem;
    margin: 50vh auto 0;
    transform: translateY(-50%);
    .img_phone {
      width: 16rem;
      display: block;
    }
    .close1 {
      width: 2rem;
      display: block;
      cursor: pointer;
      position: absolute;
      right: -1rem;
      top: -1rem;
    }
  }
}
</style>